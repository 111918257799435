import axios from "axios";
import config from "../../config";
import { GLOBALS } from "@constants";
import { saveState } from "@helpers/storage/storeLocalData";
import store from "src/redux/store";
import { AUTH } from "src/constants";

const privateInstance = axios.create({
  baseURL: config.api_url,
});

export const intermediaryInstance = axios.create({
  baseURL: config.api_url_intermediary,
  headers: {
    api_key: "ev6ZMe3vJihgdxzgcwZ8VoEEPmALCZG9L",
  },
});

export const customersInstance = axios.create({
  baseURL: config.api_url_customers,
  headers: {
    api_key: "ev6ZMe3vJihgdxzgcwZ8VoEEPmALCZG9L",
  },
});

export const configurateToken = (token) => {
  privateInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  intermediaryInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

  privateInstance.defaults.headers.token = token;
  intermediaryInstance.defaults.headers["user_token"] = token;
};

export const configurateHash = (hash) => {
  privateInstance.defaults.headers.hash = hash;
  intermediaryInstance.defaults.headers.hash = hash;
};

export const removeUserSession = () => {
  const boState = window.localStorage[GLOBALS.local_state_name];
  saveState(GLOBALS.local_state_name, { ...JSON.parse(boState), auth: {} });
  store.dispatch({ type: AUTH.AUTH_LOGOUT });
};

privateInstance.interceptors.response.use(
  function (response) {
    // refreshToken(response);
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      removeUserSession();
    } else if (error.response.status === 401) {
      removeUserSession();
    }
    return Promise.reject(error);
  }
);

intermediaryInstance.interceptors.response.use(
  function (response) {
    // refreshToken(response);
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      removeUserSession();
    } else if (error.response.status === 401) {
      removeUserSession();
    }
    return Promise.reject(error);
  }
);

customersInstance.interceptors.response.use(
  function (response) {
    // refreshToken(response);
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      removeUserSession();
    } else if (error.response.status === 401) {
      removeUserSession();
    }
    return Promise.reject(error);
  }
);

export default privateInstance;
