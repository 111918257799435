import { Box, Typography } from "@mui/material";
import { useTranslation } from "@helpers/translate";

export const BidOpenedNotification = ({
  pair_name,
  type,
  opening_price,
  contribution,
  expatriation
}) => {
  const { __ } = useTranslation();
  const duration = `${Number(expatriation.split(":")[1])} min`;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        minWidth: "270px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "baseline",
          gap: "10px"
        }}
      >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <h2>{pair_name}</h2>
          <h2>{type === "up" ? "🔼" : "🔽"}</h2>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.open_price")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              {Number(opening_price.replace(",", ""))}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.duration")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              {duration}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.amount")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              ${contribution}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
