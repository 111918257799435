import config from "../config";

export const trading = {
  en: {
    "trading.trading_history": "Trading History",
    "trading.amount": "Amount",
    "trading.time": "Time",
    "trading.duration": "Duration",
    "trading.up": "Call",
    "trading.down": "Put",
    "trading.pair": "Pair",
    "trading.payout": "Payout",
    "trading.profit": "Profit",
    "trading.open_time": "Open time",
    "trading.closing_at": "Closing time",
    "trading.forecast": "Your forecast",
    "trading.status": "Status",
    "trading.open_price": "Open price",
    "trading.close_price": "Close price",
    "trading.current_price": "Current price",
    "trading.position": "Position",
    "trading.position.buy": "Buy",
    "trading.position.sell": "Sell",
    "trading.result": "Result",
    "trading.result.won": "Won",
    "trading.result.lost": "Lost",
    "trading.result.tie": "Tie",
    "trading.difference": "Difference",
    "trading.opened": "Opened",
    "trading.closed": "Closed",
    "trading.open_bids": "You don't have any open bids",
    "trading.close_bids": "You don't have any close bids",
    "trading.pairs_nf": "No matching pairs found.",
    "trading.no_pairs": "No matching pairs.",
    "trading.bid_forecast": "Forecast",
    "trading.bid_amount": "Amount",

    "trading.for_register":
      "This section is only available to registered users. Create an account on the platform to trade and earn profits",
    "trading.continue_demo": "Continue trading on Demo",

    "trading.gift": "5000$ gift",
    "trading.gift_text":
      "5000 USD credited to your Demo account!. Or skip and Deposit now",
    "trading.skip_btn": "Skip and Deposit now",
    "trading.trade_now": "Trade now",

    "trading.welcome": `Welcome to the trading platform ${config.default_title}`,
    "trading.demo_trading": "Demo Trading",
    "trading.welcome_deposit":
      "Get high passive income monthly! Minimum investment, guaranteed payouts.",
    "trading.welcome_deposit_now": "Deposit now",

    "trading.1m": "1m",
    "trading.2m": "2m",
    "trading.3m": "3m",
    "trading.4m": "4m",
    "trading.5m": "5m",
    "trading.10m": "10m",
    "trading.15m": "15m",
    "trading.30m": "30m",
    "trading.45m": "45m",
  },

  es: {
    "trading.trading_history": "Operaciones",
    "trading.amount": "Cantidad",
    "trading.time": "Tiempo",
    "trading.duration": "Duración",
    "trading.up": "Compra",
    "trading.down": "Venta",
    "trading.pair": "Par",
    "trading.payout": "Pago",
    "trading.profit": "Ganancia",
    "trading.open_time": "Hora de apertura",
    "trading.closing_at": "Hora de cierre",
    "trading.forecast": "Tu posición",
    "trading.status": "Estado",
    "trading.open_price": "Precio apertura",
    "trading.close_price": "Precio cierre",
    "trading.current_price": "Precio actual",
    "trading.position": "Posición",
    "trading.position.buy": "Compra",
    "trading.position.sell": "Venta",
    "trading.result": "Resultado",
    "trading.result.won": "Ganada",
    "trading.result.lost": "Perdida",
    "trading.result.tie": "Empate",
    "trading.difference": "Diferencia",
    "trading.opened": "Abierto",
    "trading.closed": "Cerrado",
    "trading.open_bids": "No tienes ninguna oferta abierta",
    "trading.close_bids": "No tienes ninguna oferta cerrada",
    "trading.pairs_nf": "No se encontraron pares coincidentes.",
    "trading.no_pairs": "No hay pares coincidentes.",
    "trading.bid_forecast": "Posición",
    "trading.bid_amount": "Importe",

    "trading.for_register":
      "Esta sección solo está disponible para usuarios registrados. Crea una cuenta en la plataforma para operar y obtener ganancias",
    "trading.continue_demo": "Continuar operando en Demo",

    "trading.gift": "Regalo de 5000$",
    "trading.gift_text":
      "5000 USD acreditados en tu cuenta de Demo!. O saltar y depositar ahora",
    "trading.skip_btn": "Saltar y depositar ahora",
    "trading.trade_now": "Operar ahora",

    "trading.welcome": `Bienvenido a la plataforma de trading ${config.default_title}`,
    "trading.demo_trading": "Trading de demostración",
    "trading.welcome_deposit":
      "¡Obtén altos ingresos pasivos mensuales! Inversión mínima, pagos garantizados.",
    "trading.welcome_deposit_now": "Depositar ahora",

    "trading.1m": "1m",
    "trading.2m": "2m",
    "trading.3m": "3m",
    "trading.4m": "4m",
    "trading.5m": "5m",
    "trading.10m": "10m",
    "trading.15m": "15m",
    "trading.30m": "30m",
    "trading.45m": "45m",
  },
};

export default trading;
