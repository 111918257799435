import { Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { enUS, esES, ruRU } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/ru";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

import { updateUserKyc, updateVerification } from "@actions";
import {
  Button,
  Input,
  InternalContent,
  VerifyStatus,
} from "@components/library";
import { useTranslation } from "@helpers/translate";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LeftArrowIcon, RightArrowIcon, SwitchViewButton } from "./components";
import CountrySelect from "./components/CountrySelect";

import { intermediaryInstance } from "@helpers/axios/private.axios";
import { Helmet } from "react-helmet";
import OndatoPopup from "./components/OndatoPopup/index";
import "./index.sass";

export const MyProfile = () => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);
  const [kycData, setKycData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { __ } = useTranslation();
  const { locale } = useSelector((state) => state.localization);

  const localeText = () => {
    if (locale === "ru") {
      return ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
    } else if (locale === "es") {
      return esES.components.MuiLocalizationProvider.defaultProps.localeText;
    } else {
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    }
  };

  const { ondato_status = 1, id } = useSelector((state) => state.user.user);

  const kycDataQuery = async () => {
    const response = await intermediaryInstance.get(
      `/user/find-customer-details/${id}`
    );
    return response.data.data.item;
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: kycData?.firstName,
      email: kycData?.email,
      country: "PE",
      phone_number: kycData?.phoneNumber,
      date_birth: kycData?.dateOfBirth ?? null,
    },
  });

  const onSubmit = async (formData) => {
    setLoading(true);
    const { first_name, last_name, email, country, phone_number, date_birth } =
      formData;
    const data = {
      email,
      phoneNumber: phone_number.startsWith("+")
        ? phone_number
        : "+" + phone_number,
      firstName: first_name,
      lastName: last_name,
      countryCode: country,
      dateOfBirth: dayjs(date_birth).format("YYYY-MM-DD"),
    };

    dispatch(updateUserKyc({ data }, id))
      .catch((error) => {
        const data = error?.response?.data?.errors;
        if (data) {
          Object.keys(data).forEach((error) => {
            let nameError = error;

            if (error === "first_name" || error === "last_name") {
              nameError = "full_name";
            }

            setError(nameError, {
              type: "custom",
              message: data[error][0],
            });
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(updateVerification());
    kycDataQuery().then((res) => {
      setKycData(res);
      reset({
        first_name: res.firstName,
        last_name: res.lastName,
        email: res.email,
        country: res.countryCode,
        phone_number: res.phoneNumber,
        date_birth: res.dateOfBirth ?? null,
      });
    });
  }, []);

  return (
    <InternalContent
      bgImg={`../images/bg/bg-lamba2${theme === "white" ? "-white" : ""}.png`}
    >
      <Helmet>
        <title>
          {__("seo.my_profile")} {__("seo.divider")} {__("seo.title")}
        </title>
      </Helmet>
      <OndatoPopup />

      <div className="profile">
        <h1 className="profile__title">{__("profile.my_profile")}</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={{ lg: "24px", xs: "16px" }}>
            <Grid item lg={4} b680={6} xs={12}>
              <Input
                className="profile__inp-wrap"
                data={{
                  label: `${__("profile.first_name")}:`,
                  type: "text",
                  placeholder: `${__("profile.first_name")}:`,
                  errors,
                  settings: {
                    ...register("first_name", {
                      required: true,
                      pattern: /^[\p{L} ,.'-]+$/u,
                    }),
                  },
                  name: "first_name",
                }}
              />
            </Grid>
            <Grid item lg={4} b680={6} xs={12}>
              <Input
                className="profile__inp-wrap"
                data={{
                  label: `${__("profile.last_name")}:`,
                  type: "text",
                  placeholder: `${__("profile.last_name")}:`,
                  errors,
                  settings: {
                    ...register("last_name", {
                      required: true,
                      pattern: /^[\p{L} ,.'-]+$/u,
                    }),
                  },
                  name: "last_name",
                }}
              />
            </Grid>
            <Grid item lg={4} b680={6} xs={12}>
              <Input
                className="profile__inp-wrap"
                data={{
                  label: `${__("profile.email")}:`,
                  type: "email",
                  placeholder: "qwerty@gmail.com",
                  errors,
                  settings: {
                    ...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }),
                  },
                  message: `${__("error.email_format")}`,
                  name: "email",
                }}
              />
            </Grid>
            <Grid item lg={4} b680={6} xs={12}>
              <CountrySelect control={control} />
            </Grid>
            <Grid item lg={4} b680={6} xs={12}>
              <div className="bo-input">
                <label className="bo-input__label">
                  <p className="bo-input__label-text bo-input__label-text--help">
                    {__("profile.phone_number")}:
                  </p>
                  <div className="bo-input__wrap">
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <PhoneInput
                          value={field.value}
                          specialLabel={false}
                          placeholder="+45 (___)___-____"
                          disableDropdown
                          onChange={(e) => field.onChange(e)}
                          className={`${
                            errors["phone_number"] ? "error" : ""
                          } bo-input__input`}
                        />
                      )}
                    />

                    {errors["phone_number"] && (
                      <p className="bo-input__error">
                        {errors["phone_number"].message}
                      </p>
                    )}
                  </div>
                </label>
              </div>
            </Grid>
            <Grid item lg={4} b680={6} xs={12}>
              <div className="bo-input">
                <label className="bo-input__label">
                  <p className="bo-input__label-text">{__("profile.birth")}:</p>
                  <div className="bo-input__wrap">
                    <Controller
                      name="date_birth"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <LocalizationProvider
                          adapterLocale={locale}
                          dateAdapter={AdapterDayjs}
                        >
                          <DatePicker
                            showDaysOutsideCurrentMonth
                            fixedWeekNumber={6}
                            dayOfWeekFormatter={(day) => {
                              return day.slice(0, 2);
                            }}
                            locale={locale}
                            localeText={localeText()}
                            format="DD/MM/YYYY"
                            slotProps={{
                              popper: {
                                className: "bo-calendar-popper",
                              },
                              mobilePaper: {
                                className: "bo-calendar-popper",
                              },
                            }}
                            className="bo-calendar"
                            components={{
                              LeftArrowIcon,
                              RightArrowIcon,
                              SwitchViewButton,
                            }}
                            value={dayjs(field.value)} // Format the date manually
                            defaultValue={dayjs(field.value)}
                            onChange={(newValue) => field.onChange(newValue)}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </div>
                </label>
              </div>
            </Grid>
            <Grid item lg={4} b680={6} xs={12}>
              <div className="bo-input">
                <label className="bo-input__label">
                  <p className="bo-input__label-text">
                    {__("profile.v_status")}
                  </p>
                  <div className="bo-input__wrap">
                    <div className="verification">
                      <VerifyStatus status={ondato_status} />
                    </div>
                  </div>
                </label>
              </div>
            </Grid>
          </Grid>

          {(ondato_status === 1 || ondato_status === 4) && (
            <Button
              className="profile__btn"
              disabled={!isValid}
              size="middle"
              type="submit"
              color="orange"
              isLoading={loading}
            >
              {__("profile.start_verification")}
            </Button>
          )}
        </form>
      </div>
    </InternalContent>
  );
};

export default MyProfile;
