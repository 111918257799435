import Status from "@components/library/UI/Status";
import ConfirmWithdrawal from "@components/pages/Wallet/components/ConfirmWithdrawal";
import axios from "@helpers/axios/private.axios";
import { useTranslation } from "@helpers/translate";
import { Box, Pagination, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

const Crypto = ({ operation }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const { __ } = useTranslation();
  const getFiatHistory = async (operation, page = "1") => {
    try {
      setLoading(true);
      const operationParam =
        operation !== "all" ? `operation=${operation}` : "";
      const resp = await axios.get(
        `/gateway/history/crypto/transactions?${operationParam}&page=${page}&paginate=10`
      );
      setData(resp.data.data);
      setLoading(false);
      setTotalPages(resp.data.pagination.total);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getFiatHistory(operation, currentPage);
  }, [operation, currentPage]);

  const changePage = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Box sx={{ overflowX: "auto" }}>
      <ConfirmWithdrawal />
      <Box sx={{ minWidth: 1200 }} className="table">
        <div className="table__tr table__tr--head">
          <Box
            sx={{
              flex: "0 0 13%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("common.date")}</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 8%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("common.type")}</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 11%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("common.amount")}</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 10%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("common.network")}</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 12%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("wallet.credited_amount")}</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 24%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("wallet.destination")}</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 12%",
            }}
            className="table__td"
          >
            <p className="table__text">{__("wallet.status")}</p>
          </Box>
        </div>

        {loading ? (
          <>
            {[1, 2, 3].map((item) => (
              <div key={item} className="table__tr">
                <Skeleton
                  className="table__skeleton"
                  variant="rounded"
                  width="100%"
                />
              </div>
            ))}
          </>
        ) : data.length ? (
          <>
            {data.map((el) => (
              <div key={el.id} className="table__tr">
                <Box
                  sx={{
                    flex: "0 0 13%",
                  }}
                  className="table__td"
                >
                  <p className="table__text">{el.created_at}</p>
                </Box>
                <Box
                  sx={{
                    flex: "0 0 8%",
                  }}
                  className="table__td"
                >
                  <p className="table__text table__text--bg">
                    {el.operation === "deposit"
                      ? __("wallet.deposit_type")
                      : __("wallet.withdraw_type")}
                  </p>
                </Box>
                <Box
                  sx={{
                    flex: "0 0 11%",
                  }}
                  className="table__td"
                >
                  <p className="table__text">{el.amount}</p>
                </Box>
                <Box
                  sx={{
                    flex: "0 0 10%",
                  }}
                  className="table__td"
                >
                  <p className="table__text">TRX</p>
                </Box>
                <Box
                  sx={{
                    flex: "0 0 12%",
                  }}
                  className="table__td"
                >
                  <p className="table__text">
                    {el.amount_credited} {el.currency}
                  </p>
                </Box>
                <Box
                  sx={{
                    flex: "0 0 24%",
                  }}
                  className="table__td"
                >
                  <a href="#" className="table__text  table__text--blue">
                    {el.payee_wallet}
                  </a>
                </Box>
                <Box
                  sx={{
                    flex: "0 0 12%",
                  }}
                  className="table__td"
                >
                  <Status status={el.status} transaction={el} />
                </Box>
              </div>
            ))}
          </>
        ) : (
          <p>{__("common.not_found")}</p>
        )}
      </Box>
      {totalPages !== 1 && (
        <Pagination
          className="pagination"
          count={totalPages}
          page={currentPage}
          onChange={changePage}
          variant="outlined"
          shape="rounded"
        />
      )}
    </Box>
  );
};

export default Crypto;
