import config from "../config";

export const GLOBALS = {
  local_state_name: config.local_state_name,
  state_localization: "localization",
};

// Auth
export const AUTH = {
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_RESPONSE: "AUTH_RESPONSE",
  AUTH_LOADED: "AUTH_LOADED",
  AUTH_ERROR: "AUTH_ERROR",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_SET_USER: "AUTH_SET_USER",
  AUTH_FAILURE: "AUTH_FAILURE",
  AUTH_IS_AUTH: "AUTH_IS_AUTH",
  AUTH_DEMO: "AUTH_DEMO",
  AUTH_CHANGE_POPUP: "AUTH_CHANGE_POPUP",
  AUTH_HASH: "AUTH_HASH",
};

// Locales
export const LOCALE = {
  CHANGE_LOCALE: "CHANGE_LOCALE",
  LOADING_LOCALE: "LOADING_LOCALE",
  END_LOADING_LOCALE: "END_LOADING_LOCALE",
};

//theme
export const THEME = {
  CHANGE_THEME: "CHANGE_THEME",
};

//user
export const USER = {
  USER_REQUEST: "USER_REQUEST",
  USER_RESPONSE: "USER_RESPONSE",
  USER_LOADED: "USER_LOADED",
  USER_FIELD: "USER_FIELD",
  USER_KYC_DATA: "USER_KYC_DATA",
};

//alert
export const ALERT = {
  ALERT_SUCCESS: "ALERT_SUCCESS",
  ALERT_ERROR: "ALERT_ERROR",
  ALERT_HIDE: "ALERT_HIDE",
};

//trading
export const TRADING = {
  CHANGE_PAIR: "CHANGE_PAIR",
  UPDATE_USER_PAIR_LIST: "UPDATE_USER_PAIR_LIST",
  DELETE_FROM_USER_PAIR_LIST: "DELETE_FROM_USER_PAIR_LIST",
  ADD_USER_PAIR_LIST: "ADD_USER_PAIR_LIST",
  PAIR_PRICE_UPDATE: "PAIR_PRICE_UPDATE",
};

//bids

export const BIDS = {
  UPDATE_FIELD: "UPDATE_FIELD",
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
  ADD_TO_OPENED_HISTORY: "ADD_TO_OPENED_HISTORY",
  REMOVE_FROM_OPENED_HISTORY: "REMOVE_FROM_OPENED_HISTORY",
  ADD_TO_CLOSED_HISTORY: "ADD_TO_CLOSED_HISTORY",
  CHANGE_CLOSED_HISTORY: "CHANGE_CLOSED_HISTORY",
  CHANGE_OPENED_HISTORY: "CHANGE_OPENED_HISTORY",
  HISTORY_REQUEST: "HISTORY_REQUEST",
  HISTORY_LOADED: "HISTORY_LOADED",
  CHANGE_HISTORY_TYPE: "CHANGE_HISTORY_TYPE",
  TOGGLE_HISTORY: "TOGGLE_HISTORY",
  LOADING_CLOSED_HISTORY: "LOADING_CLOSED_HISTORY",
  REMOVE_CLOSED_HISTORY: "REMOVE_CLOSED_HISTORY",
};

export const WALLET = {
  GET_WALLETS: "GET_WALLETS",
  CHANGE_WALLET: "CHANGE_WALLET",
  UPDATE_WALLETS: "UPDATE_WALLETS",
  WALLET_LOADING: "WALLET_LOADING",

  SHOW_WITHDRAWAL: "SHOW_WITHDRAWAL",
  HIDE_WITHDRAWAL: "HIDE_WITHDRAWAL",

  SHOW_POPUP: "SHOW_SUCCESS",
  HIDE_POPUP: "HIDE_POPUP",
};

export const CHANEL = {
  CHANGE_USER_CHANEL: "CHANGE_USER_CHANEL",
};

export const BONNUS = {
  POST_BONNUS: "POST_BONNUS",
};

export * from "./routes";
