const config = {
  // Configuration file
  api_url: process.env.REACT_APP_API_URL,
  api_url_deposit: process.env.REACT_APP_MAKE_DEPOSIT,
  api_url_deposito_api_key: process.env.REACT_APP_MAKE_DEPOSIT_API_KEY,
  api_url_intermediary: process.env.REACT_APP_MAKE_DEPOSIT,
  api_url_customers: process.env.REACT_APP_API_CUSTOMERS,
  default_locale: "es",
  default_theme: "bw-default",
  default_title: "World Binary",
  local_state_name: "bw_state",
  default_pair: {
    slug: "EURUSD",
    name: "EURUSD",
    cover: `../images/icons/EUR/USD.svg`,
    pricescale: "100000",
  },
  supported_locales: process.env.REACT_APP_LANGS.split(","),
  pusher_key: process.env.REACT_APP_PUSHER_KEY,
  pusher_cluster: process.env.REACT_APP_PUSHER_CLUSTER,

  infoEmail: "info@worldbinary.pro",
  supportEmail: "support@worldbinary.pro",
};

export default config;
