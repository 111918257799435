import axios from "@helpers/axios/public.axios";
import { AUTH } from "../../constants";

export const registration =
  ({ email, password, name, lastname, partnerCode }) =>
  async (dispatch) => {
    try {
      let body = {
        email,
        password,
        first_name: name,
        last_name: lastname,
      };

      if (partnerCode) {
        body = { ...body, sponsorCode: partnerCode };
      }

      await axios.post("/register", {
        ...body,
      });
    } catch (e) {
      dispatch({ type: AUTH.AUTH_ERROR, payload: e.response.data.message });
      throw e;
    }
  };
