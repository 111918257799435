import store from "src/redux/store";
import { ALERT } from "../../constants";

export const PostBid =
  ({ type, pair_name, expatriation, contribution }, userId) =>
    async (dispatch) => {
      try {
        const state = store.getState();
        const payload = JSON.stringify({
          action: 'bid_open',
          user_id: userId,
          type,
          pair: pair_name,
          expatriation,
          contribution,
          user_token: state.auth.token,
        })
        window.socket.send(payload);
      } catch (e) {
        dispatch({
          type: ALERT.ALERT_ERROR,
          payload: e,
        });
      }
    };
