import React, { useEffect, useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Down from "@assets/images/icons/arrow-down.svg";
import { ArrowDownHistory, ArrowUpHistory } from "@components/library";
import moment from "moment-timezone";
import { CollapseOpen } from "./components/collapseOpen";
import { CollapseClose } from "./components/collapseClose";

const HistoryDayItem = ({ data, isOpened }) => {
  const [open2, setOpen2] = useState(isOpened);
  const {
    type,
    pair_name,
    closing_at,
    contribution,
    payout,
    profit,
    created_at,
    closing_price,
    opening_price,
    status
  } = data;

  const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const getDiffTime = () => {
    const currentTime = Date.now() + timeOffset;
    const closingTime = new Date(closing_at).getTime();
    const diffInMilliseconds = closingTime - currentTime;

    if (diffInMilliseconds <= 0) {
      return "00:00";
    }

    const duration = moment.duration(diffInMilliseconds);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const formattedHours = hours > 0 ? hours : "";
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const [countdown, setCountdown] = useState(getDiffTime());

  const localTime = (time) => {
    return new Date(new Date(time).getTime() - timeOffset).toLocaleTimeString();
  };

  const openTime = useMemo(() => {
    return localTime(new Date(created_at).getTime());
  }, [created_at]);

  const closeTime = useMemo(() => {
    return localTime(new Date(closing_at).getTime());
  }, [closing_at]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getDiffTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [closing_at]);

  return (
    <div className="historyDayItem">
      <button
        onClick={() => setOpen2(!open2)}
        type="button"
        className="historyDayItem__btn"
      >
        <div className="historyDayItem__icon">
          {type === "up" ? <ArrowUpHistory /> : <ArrowDownHistory />}
        </div>
        <div className="historyDayItem__info historyDayItem__info--1">
          <p className="historyDayItem__text">{pair_name}</p>
          <p className="historyDayItem__text historyDayItem__text--nowrap">
            <strong>
              ${contribution} ({payout}%)
            </strong>
          </p>
        </div>
        <div className="historyDayItem__info historyDayItem__info--3">
          {!!countdown && (
            <p
              className="historyDayItem__text"
              style={{ color: countdown != "00:00" && "#F9B400" }}
            >
              <strong>{countdown}</strong>
            </p>
          )}
          <p
            className={`historyDayItem__text ${
              status === 2
                ? "historyDayItem__text--green"
                : status === 0
                ? "historyDayItem__text--red"
                : "historyDayItem__text--white"
            }`}
          >
            <strong>
              {status === 2 ? "+$" + profit : status === 0 && "-$" + contribution}
            </strong>
          </p>
        </div>
        <div className="historyDayItem__arrow">
          <img
            className={`${open2 ? "historyDayItem__arrow-active" : ""}`}
            src={Down}
            alt="#"
          />
        </div>
      </button>
      <Collapse
        className="historyDayItem__content"
        in={open2}
        timeout="auto"
        unmountOnExit
      >
        {parseFloat(closing_price) == 0 ? (
          <CollapseOpen
            openTime={openTime}
            closeTime={closeTime}
            type={type}
            payout={payout}
            status={status}
            profit={profit}
            opening_price={opening_price}
          />
        ) : (
          <CollapseClose
            openTime={openTime}
            closeTime={closeTime}
            type={type}
            payout={payout}
            status={status}
            profit={profit}
            opening_price={opening_price}
            closing_price={closing_price}
            contribution={contribution}
          />
        )}
      </Collapse>
    </div>
  );
};

export default React.memo(HistoryDayItem);
